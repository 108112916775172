












import { Component, Vue, Emit } from 'vue-property-decorator'
import { ContractSymbol, CryptoSymbol } from '@/services/data'

@Component
export default class CryptoSearch extends Vue {
  name = 'CryptoSearch'
  input: string = null

  @Emit() addSymbol () {
    this.$emit('addcrypto', { Code: this.input } as CryptoSymbol)
    this.input = null
  }
}
