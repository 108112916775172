
























import { Component, Vue, Emit } from 'vue-property-decorator'
import { ContractSymbol, Option } from '@/services/data'
import symbolService from '@/services/symbolService'
import utility from '@/common/utility'

@Component
export default class OptionSearch extends Vue {
  name = 'OptionSearch'
  input = ''
  option: Option = { Expiration: '', Right: '', Strike: null, Multiplier: null, Code: null }
  symbol: ContractSymbol
  expirationList: Set<string> = new Set<string>()
  strikeList: Set<number>= new Set<number>()
  multiplier: number;
  isSearching = false

  async search () {
    if (this.input.trim().length >= 1) {
      await this.doSearch()
    }
  }

  @Emit() addOption () {
    if (this.multiplier) {
      this.option.Multiplier = this.multiplier
      return { symbol: this.symbol, option: this.option }
    }
    return null
  }

  async doSearch () {
    if (this.isSearching) return
    const searchInput = this.input
    this.isSearching = true
    const res = await symbolService.searchOptionsAsync(searchInput, this.option.Expiration)
    this.isSearching = false
    if (res.Error) {
      await this.$alert(res.Error)
      this.clear()
      return
    }
    if (!res.Result) {
      await this.$alert('No security definition')
      this.clear()
      return
    }
    this.symbol = res.Result.Symbol
    this.expirationList = new Set<string>(res.Result.Expirations.map(p => utility.splitDate(p)))
    this.multiplier = res.Result.Multiplier
    this.strikeList = new Set<number>(res.Result.Strikes)
  }

  get isExpReady () {
    return this.input && this.expirationList.size > 0
  }

  get isRightReady () {
    return this.isExpReady && this.option.Expiration
  }

  get isStrikeReady () {
    return this.isRightReady && this.option.Right
  }

  clear () {
    this.expirationList.clear()
    this.strikeList.clear()
    this.symbol = null
    this.option.Expiration = null
    this.option.Right = null
    this.multiplier = null
    this.input = ''
  }

  async filterByRight (right: string) {
    this.option.Strike = null
  }
}
