















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Sort, Option, WatchOption, WatchSymbol, CryptoSymbol, WatchCrypto } from '@/services/data'
import draggable from 'vuedraggable'
import utility from '@/common/utility'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import SymbolName from '@/components/SymbolName.vue'
import CryptoSearch from '@/components/CryptoSearch.vue'

@Component({
  components: {
    CryptoSearch,
    draggable,
    NumberRowEditor,
    SymbolName
  }
})
export default class WatchCryptos extends Vue {
  name = 'WatchCryptos'
  @Prop() Items: WatchCrypto[]
  @Prop() Keys: string[]
  @Prop() IsRead: boolean
  @Prop() IsSystem: boolean
  @Prop() draggable: boolean
  showSymbolSearch = false
  sort: Sort = { key: null, direction: null }
  currentCrypto: WatchCrypto

  $num (num: number): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
    return null
  }

  $fix (num: number, end?: string): string {
    if (num != null && num !== undefined) {
      return num.toFixed(2) + (end || '')
    }
    return null
  }

  sortItems (key: string, direction: string) {
    this.sort = { key, direction }
    const sort = (a: WatchCrypto, b: WatchCrypto): number => {
      const v = direction === 'Asc' ? 1 : -1
      const left = this.getByKey(a, key)
      const right = this.getByKey(b, key)
      if (left === right) return 0
      return left > right ? v : v * -1
    }
    if (sort) {
      this.Items.sort(sort)
    }
  }

  getByKey = utility.getByKey

  @Emit('dragEnd')
  async dragEnd () {
    for (let i = 0; i < this.Items.length; i++) {
      // this.Items[i].Rank = i + 1
    }
  }

  @Emit('addcrypto')
  async addCrypto (item: CryptoSymbol) {
    if (item == null) return
    this.showSymbolSearch = false
    return item
  }

  @Emit('deletecrypto')
  delItem (item: WatchCrypto) {
    return item
  }
}
