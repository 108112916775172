




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '@/common/element-val'

@Component
export default class RangeForm extends Vue {
  $refs: {
    modelForm: ElForm;
  }

  @Prop() low: number
  @Prop() run: boolean
  @Prop() high: number
  model: { Low: number; High: number; Run: boolean } = { Low: null, High: null, Run: false }

  rules = {
    Low: validation.requiredNumberRule(),
    High: validation.requiredNumberRule()
  }

  mounted () {
    this.model.Low = this.low
    this.model.High = this.high
    this.model.Run = this.run
  }

  @Watch('low')
  onLowChanged (v: number) {
    this.model.Low = v
  }

  @Watch('high')
  onHighChanged (v: number) {
    this.model.High = v
  }

  @Watch('run')
  onRunChanged (v: boolean) {
    this.model.Run = v
  }

  submit () {
    this.$refs.modelForm.validate((valid: boolean) => {
      if (valid) {
        this.$emit('submit', this.model)
      }
    })
  }
}
