





















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { ContractSymbol, WatchSymbol, Sort } from '@/services/data'
import draggable from 'vuedraggable'
import utility from '@/common/utility'
import SymbolSearch from '@/components/SymbolSearch.vue'
import SetSymbolAlert from '@/components/SetAlertPrice.vue'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import checkListService from '@/services/checkListService'
import TransactionForm from '@/components/TransactionForm.vue'
import SymbolName from '@/components/SymbolName.vue'

@Component({
  components: {
    draggable,
    SymbolSearch,
    SetSymbolAlert,
    NumberRowEditor,
    TransactionForm,
    SymbolName
  }
})
export default class WatchSymbols extends Vue {
  name = 'WatchBonds'
  @Prop() Items: WatchSymbol[]
  @Prop() Keys: string[]
  @Prop() IsRead: boolean
  @Prop() IsSystem: boolean
  @Prop() draggable: boolean
  showSymbolSearch = false
  alertPriceSettingDialog = false
  sort: Sort = { key: null, direction: null }
  currentSymbol: WatchSymbol
  showTargetDialog = false
  editingTarget: number

  $num (num: number): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
    return null
  }

  $fix (num: number, end?: string): string {
    if (num != null && num !== undefined) {
      return num.toFixed(2) + (end || '')
    }
    return null
  }

  sortItems (key: string, direction: string) {
    this.sort = { key, direction }
    const sort = (a: WatchSymbol, b: WatchSymbol): number => {
      const v = direction === 'Asc' ? 1 : -1
      const left = this.getByKey(a, key)
      const right = this.getByKey(b, key)
      if (left === right) return 0
      return left > right ? v : v * -1
    }
    if (sort) {
      this.Items.sort(sort)
    }
  }

  getByKey = utility.getByKey

  @Emit('dragEnd')
  async dragEnd () {
    for (let i = 0; i < this.Items.length; i++) {
      this.Items[i].Rank = i + 1
    }
  }

  @Emit('addSymbol')
  async addSymbol (item: ContractSymbol) {
    this.showSymbolSearch = false
    return item
  }

  @Emit('deleteSymbol')
  delItem (item: WatchSymbol) {
    return item
  }

  @Watch('Items')
  onItemsChanged (v: any) {
    if (this.IsSystem) {
      const tid = parseInt(this.$route.query.targetSymbolId as string)
      const find = this.Items.find(p => p.Symbol.SymbolId === tid)
      if (find) {
        this.showEditTarget(find)
      }
    }
  }

  alertAdded (res: { symbolId: number; count: number }) {
    this.currentSymbol.Alert = res.count > 0
  }

  async targetSaved () {
    const targetValue = this.editingTarget
    const res = await checkListService.setTargetAsync({
      SymbolId: this.currentSymbol.Symbol.SymbolId,
      Target: targetValue
    })
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      const find = this.Items.find(p => p.Symbol.SymbolId === this.currentSymbol.Symbol.SymbolId)
      if (find) {
        find.Target = targetValue
      }
      this.showTargetDialog = false
    }
  }

  async setAlertPrice (item: WatchSymbol) {
    if (item) {
      this.currentSymbol = item
      this.alertPriceSettingDialog = true
    }
  }

  showEditTarget (item: WatchSymbol) {
    const find = this.Items.find(p => p.Symbol.SymbolId === item.Symbol.SymbolId)
    if (find) {
      this.editingTarget = find.Target
    }
    this.currentSymbol = item
    this.showTargetDialog = true
  }
}
