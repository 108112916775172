
















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { ContractSymbol, SymbolType } from '@/services/data'
import symbolService from '@/services/symbolService'

@Component
export default class SymbolSearch extends Vue {
  name = 'SymbolSearch'
  input: string = null
  msgId: number = null
  loading = false
  symbols: ContractSymbol[] = []
  @Prop() title: string
  @Prop() symbolType: SymbolType

  async autoSearch () {
    if (this.input.trim().length >= 3) {
      await this.doSearch()
    }
  }

  async search () {
    if (this.input.trim().length >= 1) {
      await this.doSearch()
    }
  }

  @Emit() addSymbol (symbol: ContractSymbol) {
    this.symbols = []
    this.input = null
    this.$emit('selected', symbol)
  }

  async doSearch () {
    this.symbols = []
    this.loading = true
    const res = await symbolService.searchSymbolsAsync(this.input, this.symbolType)
    this.loading = false
    if (res.Error) {
      await this.$alert(res.Error)
      return
    }
    if (res.Result && res.Result.length === 0) {
      const confirm = await this.$confirm('Could not find the relevant securities, do you want to add it directly?')
      if (confirm === 'confirm') {
        let type = 'STK'
        if (this.symbolType === SymbolType.Bond) {
          type = 'BOND'
        }
        this.addSymbol({ Symbol: this.input, Currency: '', PrimaryExch: '', SecType: type, SymbolUId: 0, SymbolId: 0, CompanyName: '' })
      }
    }
    this.symbols = res.Result
  }
}
