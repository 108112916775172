



























































































































import { Component, Vue } from 'vue-property-decorator'
import {
  ContractSymbol,
  PriceMsgContent,
  WatchSymbol,
  WatchGroup,
  WatchListName,
  PriceCls,
  OptionRow,
  Option, WatchOption, OptionMsgContent, CryptoSymbol, WatchCrypto
} from '@/services/data'
import { setPriceValues } from '@/services/func'
import TextRowEditor from '@/components/TextRowEditor.vue'
import ColumnSelector from '@/components/ColumnsSelector.vue'
import utility from '@/common/utility'
import WatchSymbols from '@/components/WatchSymbols.vue'
import WatchOptions from '@/components/WatchOptions.vue'
import WatchBonds from '@/components/WatchBonds.vue'
import WatchListService from '@/services/watchListService'
import ws, { SocketMsg } from '@/services/socket'
import { Route } from 'vue-router'
import ShareWatchList from '@/components/ShareWatchList.vue'
import Draggable from 'vuedraggable'
import settingService from '@/services/settingService'
import WatchCryptos from '@/components/WatchCryptos.vue'

@Component({
  components: {
    WatchCryptos,
    WatchBonds,
    ShareWatchList,
    TextRowEditor,
    WatchSymbols,
    WatchOptions,
    ColumnSelector,
    Draggable
  }
})
export default class Watchlist extends Vue {
  WatchList: WatchGroup[] = []
  currentGroupId: number = null
  currentKeys: string[] = []
  currentDefaultKeys: string[] = []
  currentPopup: WatchListName = null
  newGroupName: string = null
  newWatchDialog = false
  renameWatchDialog = false
  userSearchDialog = false
  colSettingDialog = false
  loading = false
  runningInterval: number
  dataSource: string = null

  async mounted () {
    ws.InitAll(this.msgCallBack)
    let wId = parseInt(this.$route.query.id as string)
    const namesRes = await WatchListService.getWatchListNamesAsync()
    for (const name of namesRes.Result) {
      if (name.Keys == null || name.Keys.length === 0) {
        name.Keys = utility.copyArray(this.getDefaultKeys('STK'))
      }
      if (name.OptKeys == null || name.OptKeys.length === 0) {
        name.OptKeys = utility.copyArray(this.getDefaultKeys('OPT'))
      }
      if (name.CryptoKeys == null || name.CryptoKeys.length === 0) {
        name.CryptoKeys = utility.copyArray(this.getDefaultKeys('CRYPTO'))
      }
      if (name.BondKeys == null || name.BondKeys.length === 0) {
        name.BondKeys = utility.copyArray(this.getDefaultKeys('BOND'))
      }
      this.WatchList.push({ WatchName: name, Symbols: [], Options: [], Cryptos: [] })
    }
    if (isNaN(wId) && this.WatchList.length > 0) {
      wId = this.WatchList[0].WatchName.WatchListId
    }
    this.currentGroupId = wId
    await this.loadWatchData()
    const dsRes = (await settingService.readUserSettingsByNamesAsync(['StockDataSource'])).Result
    if (dsRes.length > 0) this.dataSource = dsRes[0].Value
    this.runningInterval = setInterval(async () => {
      await this.getDownloadStatusAsync()
    }, 5000)
  }

  async dragEnd () {
    for (let i = 0; i < this.WatchList.length; i++) {
      this.WatchList[i].Rank = i + 1
    }
    await WatchListService.updateRankAsync(this.WatchList.map(p => {
      return { WatchListId: p.WatchName.WatchListId, Rank: p.Rank }
    }))
  }

  getDefaultKeys (type: string) {
    const current = this.findCurrent()
    if (type === 'OPT') {
      return ['Symbol', 'Strike', 'ExpirationDate', 'Right', 'Multiplier', 'NextEarnDate', 'Price', 'Currency', 'ExchRate', 'LastUpdated']
    } else if (type === 'STK') {
      const keys = ['Symbol', 'Close', 'Price', 'Volume', 'AvgVolume', 'Change', 'ChangeRate', 'NextEarnDate', 'LastUpdated', 'ExchRate', 'Currency']
      if (current && current.WatchName.IsSystem) {
        return keys.concat('MarketValue', 'DailyPL', 'AverageCost', 'Position')
      } else {
        return keys
      }
    } else if (type === 'BOND') {
      return ['ISIN', 'Coupon', 'MaturityDate', 'LastTradePrice', 'YTM', 'CouponDate', 'Frequency', 'Currency']
    } else {
      return ['Symbol', 'Price', 'Currency', 'ExchRate', 'LastUpdated']
    }
  }

  findCurrent () {
    return this.WatchList.find(p => p.WatchName.WatchListId === this.currentGroupId)
  }

  async transChanged () {
    await this.loadWatchData()
  }

  async getDownloadStatusAsync () {
    if (this.currentGroupId) {
      const find = this.findCurrent()
      if (!find.WatchName.IsSystem) return
      const res = await WatchListService.getDownloadStatusAsync(find.Symbols.map(p => p.Symbol.SymbolId))
      if (res.Result) {
        for (const item of find.Symbols) {
          const isDownloading = res.Result.findIndex(p => p.SymbolId === item.Symbol.SymbolId && p.IsDownloading) >= 0
          if (item != null) {
            item.IsDownloading = isDownloading
          }
        }
      }
    }
  }

  async loadWatchData () {
    if (this.currentGroupId) {
      const items = await this.getWatchListAsync(this.currentGroupId)
      if (items.length > 0) {
        const find = this.findCurrent()
        if (find) {
          find.Symbols = items[0].Symbols
          find.Options = items[0].Options
          find.Cryptos = items[0].Cryptos
          ws.SendAll({
            page: 'watchlist',
            symbols: find.Symbols.map(p => p.Symbol.SymbolId),
            options: find.Options.map(p => p.Option.OptionId),
            cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
          })
        }
      }
    }
  }

  async getWatchListAsync (watchListId: number) {
    this.loading = true
    const res = await WatchListService.getWatchListsAsync(watchListId)
    for (const group of res.Result) {
      group.Symbols.forEach(p => {
        if (p.Price == null) {
          p.Price = new PriceCls({ Open: null, Close: null, Last: null, Bid: null })
          p.Symbol.Detail = p.Symbol.Detail ? JSON.parse(p.Symbol.Detail as string) : {}
        }
      })
      group.Options.forEach(p => {
        if (p.Price == null) {
          p.Price = new PriceCls({ Open: null, Close: null, Last: null, Bid: null })
        }
      })
    }
    this.loading = false
    return res.Result
  }

  async beforeRouteUpdate (to: Route, from: Route, next: () => void) {
    const wId = parseInt(to.query.id as string)
    if (!isNaN(wId) && wId > 0) {
      this.currentGroupId = wId
      await this.loadWatchData()
    }
    next()
  }

  destroyed (): void {
    ws.RemoveAll(this.msgCallBack)
    if (this.runningInterval) clearInterval(this.runningInterval)
  }

  async msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resStk') {
      for (const w of this.WatchList) {
        for (const sp of w.Symbols) {
          if (sp.Price == null) {
            sp.Price = new PriceCls({ Open: null, Close: null, Last: null })
          }
          if (sp.Symbol.SymbolUId === msg.MsgId) {
            const content = msg.Content as PriceMsgContent
            w.LastUpdated = content.LastTime
            sp.Price.Status = content.Status
            setPriceValues(content, sp.Price)
            if (sp.Portfolio) {
              sp.Portfolio.DailyPL = ((sp.Price.Last - sp.Price.Close) * sp.Portfolio.Position)
            }
          }
        }
      }
    } else if (msg.Action === 'resOpt') {
      for (const w of this.WatchList) {
        for (const sp of w.Options) {
          if (sp.Option.OptionUId === msg.MsgId) {
            const content = msg.Content as OptionMsgContent
            w.LastUpdated = content.LastTime
            if (content.Name === 'OptPrice') {
              sp.Price.Last = content.Value
              sp.Price.LastUpdated = content.LastTime
            } else if (content.Name === 'Open') {
              sp.Price.Open = content.Value
            } else if (content.Name === 'Close') {
              sp.Price.Close = content.Value
            }
          }
        }
      }
    }
  }

  setActiveTab (id: number) {
    this.$router.push('/home/watchlist?id=' + id)
  }

  async addTab () {
    const res = await WatchListService.addWatchListAsync(this.newGroupName)
    if (res.Result) {
      const added = await this.getWatchListAsync(res.Result.WatchListId)
      for (const item of added) {
        if (item.WatchName.Keys == null || item.WatchName.Keys.length === 0) {
          item.WatchName.Keys = utility.copyArray(this.getDefaultKeys('STK'))
        }
        if (item.WatchName.OptKeys == null || item.WatchName.OptKeys.length === 0) {
          item.WatchName.OptKeys = utility.copyArray(this.getDefaultKeys('OPT'))
        }
        if (item.WatchName.CryptoKeys == null || item.WatchName.CryptoKeys.length === 0) {
          item.WatchName.CryptoKeys = utility.copyArray(this.getDefaultKeys('CRYPTO'))
        }
        if (item.WatchName.BondKeys == null || item.WatchName.BondKeys.length === 0) {
          item.WatchName.BondKeys = utility.copyArray(this.getDefaultKeys('BOND'))
        }
        this.WatchList.push(item)
      }
      this.newWatchDialog = false
      this.newGroupName = null
    } else {
      await this.$alert(res.Error)
    }
  }

  async deleteTab () {
    const find = this.currentPopup
    if (find) {
      if (confirm('Are you sure you want to delete this item?')) {
        const res = await WatchListService.deleteWatchListAsync(find.WatchListId)
        if (res.Result) {
          const delItem = this.WatchList.find(p => p.WatchName.WatchListId === find.WatchListId)
          if (delItem) {
            utility.removeArrayItem(this.WatchList, delItem)
            const current = this.findCurrent()
            ws.SendAll({
              page: 'watchlist',
              symbols: current ? current.Symbols.map(p => p.Symbol.SymbolId) : [],
              options: current ? current.Options.map(p => p.Option.OptionId) : [],
              cryptos: current ? current.Cryptos.map(p => p.Symbol.CryptoId) : []
            })
          }
        } else {
          await this.$alert(res.Error)
        }
      }
    }
  }

  async duplicateTab () {
    const find = this.currentPopup
    if (find) {
      const res = await WatchListService.duplicateWatchListAsync(find.WatchListId)
      if (res.Result) {
        const newList = await this.getWatchListAsync(res.Result.WatchListId)
        for (const item of newList) {
          if (item.WatchName.Keys == null || item.WatchName.Keys.length === 0) {
            item.WatchName.Keys = utility.copyArray(this.getDefaultKeys('STK'))
          }
          if (item.WatchName.OptKeys == null || item.WatchName.OptKeys.length === 0) {
            item.WatchName.OptKeys = utility.copyArray(this.getDefaultKeys('OPT'))
          }
          this.WatchList.push(item)
        }
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  showShareUser () {
    this.userSearchDialog = true
  }

  async sharedToUser (data: any[]) {
    const find = this.currentPopup
    if (find) {
      const res = await WatchListService.shareWatchListAsync(find.WatchListId, data[0].UserId, data[1])
      if (res.Result) {
        this.userSearchDialog = false
        this.$message({ message: 'Watchlist shared to user', type: 'success' })
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async unSharedToUser (userId: number) {
    const find = this.currentPopup
    if (find) {
      const res = await WatchListService.unShareWatchListAsync(find.WatchListId, userId)
      if (res.Result) {
        this.userSearchDialog = false
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  showReNameDialog () {
    const find = this.currentPopup
    if (find) {
      this.newGroupName = find.Name
    }
    this.renameWatchDialog = true
  }

  showColumnDialog (keys: string[], type: string) {
    const find = this.findCurrent()
    if (find) {
      this.colSettingDialog = true
      this.currentKeys = keys
      this.currentDefaultKeys = this.getDefaultKeys(type)
    }
  }

  async saveColumnKeys (keys: string[]) {
    if (keys.length === 0) return
    const find = this.findCurrent()
    if (find) {
      await WatchListService.saveKeysAsync(find.WatchName.WatchListId, keys)
      find.WatchName.Keys = keys
    }
  }

  async rename () {
    const find = this.currentPopup
    if (find) {
      const res = await WatchListService.renameWatchListAsync(find.WatchListId, this.newGroupName)
      if (res.Result) {
        find.Name = this.newGroupName
        this.renameWatchDialog = false
        this.newGroupName = null
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async addSymbol (item: ContractSymbol) {
    const find = this.findCurrent()
    if (find) {
      const res = await WatchListService.addSymbolToWatchListAsync(find.WatchName.WatchListId, item)
      if (res.Result) {
        item.SymbolId = res.Result.Symbol.SymbolId
        console.log(res.Result)
        const newItem: WatchSymbol = {
          WatchListId: find.WatchName.WatchListId,
          Symbol: res.Result.Symbol,
          NextEarnDate: null,
          Alert: false,
          Price: new PriceCls(res.Result.Price || { Open: null, Close: null, Last: null }),
          ExRate: null
        }
        find.Symbols.push(newItem)
        ws.SendAll({
          page: 'watchlist',
          symbols: find.Symbols.map(p => p.Symbol.SymbolId),
          options: find.Options.map(p => p.Option.OptionId),
          cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
        })
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async addOption (opt: { symbol: ContractSymbol; option: Option }) {
    if (!opt || !opt.option.Expiration || !opt.option.Strike) return
    const find = this.findCurrent()
    if (find) {
      const res = await WatchListService.addOptionToWatchListAsync(find.WatchName.WatchListId, opt.symbol, opt.option)
      if (res.Result) {
        find.Options.push(res.Result)
        ws.SendAll({
          page: 'watchlist',
          symbols: find.Symbols.map(p => p.Symbol.SymbolId),
          options: find.Options.map(p => p.Option.OptionId),
          cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
        })
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async stkDragged () {
    const find = this.findCurrent()
    await WatchListService.updateSymbolRankAsync(find.WatchName.WatchListId, find.Symbols.map(p => {
      return { SymbolId: p.Symbol.SymbolId, Rank: p.Rank }
    }))
  }

  async delSymbol (item: WatchSymbol) {
    if (confirm('Are you sure you want to delete this item?')) {
      const find = this.findCurrent()
      if (find) {
        const res = await WatchListService.deleteSymbolFromWatchListAsync(find.WatchName.WatchListId, item.Symbol.SymbolId)
        if (res.Result) {
          utility.removeArrayItem(find.Symbols, item)
          ws.SendAll({
            page: 'watchlist',
            symbols: find.Symbols.map(p => p.Symbol.SymbolId),
            options: find.Options.map(p => p.Option.OptionId),
            cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
          })
        } else {
          await this.$alert(res.Error)
        }
      }
    }
  }

  async delOption (item: WatchOption) {
    if (confirm('Are you sure you want to delete this item?')) {
      const find = this.findCurrent()
      if (find) {
        const res = await WatchListService.deleteOptionFromWatchListAsync(find.WatchName.WatchListId, item.Option.OptionId)
        if (res.Result) {
          utility.removeArrayItem(find.Options, item)
          ws.SendAll({
            page: 'watchlist',
            symbols: find.Symbols.map(p => p.Symbol.SymbolId),
            options: find.Options.map(p => p.Option.OptionId),
            cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
          })
        } else {
          await this.$alert(res.Error)
        }
      }
    }
  }

  async addCrypto (item: CryptoSymbol) {
    if (!item) return
    const find = this.findCurrent()
    if (find) {
      const res = await WatchListService.addCryptoToWatchListAsync(find.WatchName.WatchListId, item)
      if (res.Result) {
        find.Cryptos.push(res.Result)
        ws.SendAll({
          page: 'watchlist',
          symbols: find.Symbols.map(p => p.Symbol.SymbolId),
          options: find.Options.map(p => p.Option.OptionId),
          cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
        })
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async delCrypto (item: WatchCrypto) {
    if (confirm('Are you sure you want to delete this item?')) {
      const find = this.findCurrent()
      if (find) {
        const res = await WatchListService.deleteCryptoFromWatchListAsync(find.WatchName.WatchListId, item.Symbol.CryptoId)
        if (res.Result) {
          utility.removeArrayItem(find.Cryptos, item)
          ws.SendAll({
            page: 'watchlist',
            symbols: find.Symbols.map(p => p.Symbol.SymbolId),
            options: find.Options.map(p => p.Option.OptionId),
            cryptos: find.Cryptos.map(p => p.Symbol.CryptoId)
          })
        } else {
          await this.$alert(res.Error)
        }
      }
    }
  }
}
