







































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import memberService, { MemberModel, MemberUpdateModel } from '@/services/memberService'
import watchListService, { SharedStatus } from '@/services/watchListService'
import { WatchListName } from '@/services/data'

interface MemberShared {
  Member: MemberModel;
  Status: SharedStatus;
}

@Component
export default class ShareWatchList extends Vue {
  name = 'ShareWatchList.vue'
  input: string = null
  shareState = 'readonly'
  currentUser: MemberUpdateModel = null
  members: MemberShared[] = []
  shared: SharedStatus[] = []
  @Prop() watchlistName: WatchListName

  @Emit('selected') shareToMember (member: MemberModel) {
    this.members = []
    this.input = null
    const res = [member, this.shareState === 'readonly']
    return res
  }

  @Emit('cancelled') unShareToMember (userId: number) {
    this.members = []
    this.input = null
    const res = userId
    return res
  }

  async mounted () {
    this.currentUser = (await memberService.getCurrentAsync()).Result
    const sharedUsers = await watchListService.getSharedUsersAsync(this.watchlistName.WatchListId)
    if (sharedUsers.Error) {
      await this.$alert(sharedUsers.Error)
    } else {
      this.shared = sharedUsers.Result
    }
  }

  async changeShareStatus (item: SharedStatus) {
    const res = await watchListService.changeShareStatusAsync(this.watchlistName.WatchListId, item.UserId, item.IsReadOnly)
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.$message({ message: 'Share status changed', type: 'success' })
    }
  }

  async search () {
    const res = await memberService.getAllAsync(this.input)
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.members = res.Result.filter(p => p.LoginId !== this.currentUser.LoginId).map(p => {
        return {
          Member: p,
          Status: this.shared.find(q => q.UserId === p.UserId)
        }
      }).filter(q => q.Status == null)
    }
  }
}
